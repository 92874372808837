<template>
  <div
    class="panel-contacts"
    ref="panel"
    id="contacts-tab"
  >
    <div
      class="mx-3 h2 py-3"
      style="cursor: pointer"
      @click="close()"
    >
      <span class="fe fe-arrow-left me-3" />
      {{ $t('contacts') }}
    </div>
    <search-input
      style="color: red !important; background-color: #edf2f9; border-radius: 10px"
      class="col px-3 mx-3 my-3"
      @search="search"
    />
    <template
      v-for="(list, letter) in contacts"
      :key="letter"
    >
      <div
        class="mx-3 my-3 h2 letter text-center fw-medium"
      >
        {{ letter }}
      </div>
      <contact-card
        v-for="(contact, index) in list"
        :key="index"
        :contact="contact"
      />
    </template>
    <div v-if="loadingFlags.findContacts">
      <div
        v-for="index in 15"
        :key="index"
        class="d-flex align-items-center px-3 py-2 contact skeleton-loading"
      >
        <div class="avatar">
          <div
            src="@assets/img/avatars/profiles/nome-user.png"
            class="avatar-img rounded-circle background-loading"
          />
        </div>
        <div class="col ps-3">
          <div class="name background-loading fw-medium" />
          <div class="text-muted small phone background-loading fw-light" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import SearchInput from "@components/search-input";
import _ from "lodash";
import ContactCard from "./contacts-types/contact-card.vue";

export default {

  /**
   *
   */
  data() {
    return {
      scrollHeightBackup: 0,
    };
  },

  /**
   * emits
   */
  emits: ["close"],

  /**
   *
   */

  mounted() {
    this.setCurrentPage(1);
    this.clearContacts();
    this.findContacts();
    this.$refs.panel.addEventListener("scroll", this.scroll);
  },

  /**
   *
   */
  computed: {
    /**
     *
     */
    ...mapGetters("whatsapp/contact", {
      contacts: "getContacts",
      loadingFlags: "getLoadingFlags",
      pagination: "getPagination",
    }),
  },

  components: {
    SearchInput,
    ContactCard
  },

  /**
   *  methods
   */
  methods: {
    /**
     * actions
     */
    ...mapActions("whatsapp/contact", ["findContacts"]),
    ...mapMutations("whatsapp/contact", ["setCurrentPage", "clearContacts", "setFilters", "setCurrentPage"]),

    /**
     *  scroll event
     */
    scroll() {
      //TODO: improve
      let panel = this.$refs.panel;
      if (panel.scrollTop + panel.clientHeight >= panel.scrollHeight - 60) {
        if (this.loadingFlags.findContacts) {
          panel.scrollTo(0, panel.scrollHeight - 60);
          return;
        }
        this.scrollHeightBackup = panel.scrollTop;
        if (this.pagination.current_page < this.pagination.total_pages) {
          this.setCurrentPage(this.pagination.current_page + 1);
          this.findContacts().then(() => {
            panel.scrollTo(0, this.scrollHeightBackup);
          });
        }
      }
    },

     /**
     * search function for search input
     */
    search: _.debounce(function (term) {
        this.setFilters({
          search: term,
        });
        this.setCurrentPage(1);
        this.clearContacts();
        this.findContacts();
    }, 300),

    /**
     * notify parent when modal is closed
     */
    close() {
      this.$emit("close");
    },
  },
};
</script>

<style lang="scss" scoped>

.panel-contacts {
  background-color: $color-text-light;

  height: 100vh;

  overflow-x: hidden;
  overflow-y: auto;

  border-right: 1px solid #dddfe3;

  .letter {
    width: 48px;
    color: $color-blue-300;
  }

  .skeleton-loading {
    cursor: pointer;

    .name,
    .phone {
      color: transparent !important;
      user-select: none;
    }
    .name {
      margin-bottom: 6px;
      height: 15px;
      width: 150px;
    }
    .phone {
      color: #707991;
      margin-top: 7px;
      height: 12px;
      width: 100px;
    }

    &:hover {
      background-color: $color-text-light;
    }
  }
}

</style>
