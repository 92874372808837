<template>
  <nav
    style="overflow: visible !important;"
    class="
      navbar navbar-vertical navbar-vertical-sm
      fixed-start
      navbar-expand-md navbar-light
    "
    :style="sidebarStyle"
  >
    <div class="container-fluid">
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#sidebarSmallCollapse"
        aria-controls="sidebarSmallCollapse"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon" />
      </button>

      <router-link
        class="navbar-brand"
        to="/manager"
      >
        <img
          src="@/assets/img/logo.svg"
          class="navbar-brand-img mx-auto"
        >
      </router-link>

      <div
        class="collapse navbar-collapse"
        id="sidebarSmallCollapse"
      >
        <hr class="navbar-divider d-none d-md-block mt-0 mb-3">
        <ul class="navbar-nav">
          <li
            class="nav-item dropend"
            v-for="(item, i) of menu.top"
            :key="i"
          >
            <template v-if="item.type !== 'separator' && user && item?.roles.includes(user.role.name)">
              <router-link
                class="nav-link"
                :class="{ 'active': item.active_name === 'whatsapp' }"
                :to="item.path"
                :title="$t(item.title)"
              >
                <i :class="item.icon" />
              </router-link>
            </template>
            <template v-else-if="item.type === 'separator'">
              <hr
                class="navbar-divider mx-3"
                v-if="isManager"
              >
            </template>
          </li>
        </ul>
      </div>

      <div class="dropup-center dropup">
        <button
          class="btn btn-gray-blue dropdown-toggle"
          type="button"
          data-bs-toggle="dropdown"
          aria-expanded="false"
          data-bs-auto-close="outside"
        >
          <i class="fa-regular fa-ellipsis" />
        </button>
        <ul class="dropdown-menu profile-action-buttons">
          <li>
            <profile-action-buttons
              :menu="menu"
              :user="user"
              expanded
              :expand="false"
            />
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
import menu from "@dialer/manager/menu-config.js";
import {mapGetters} from "vuex";
import ProfileActionButtons from "@dialer/manager/components/sidebar/profile-action-buttons.vue";

export default {
  components: {ProfileActionButtons},

  data() {
    return {
      menu: [],
    };
  },

  mounted() {
    this.menu = menu;
  },
  computed: {
    ...mapGetters("auth", {
      showPaymentModal: "showPaymentModal",
      user: "getUser",
      isManager: "isManager",
    }),
    sidebarStyle() {
      return {
        zIndex: this.showPaymentModal ? 1068 : 1000
      };
    }
  },
};
</script>

<style lang="scss" scoped>
.btn-logout {
  background-color: $color-gray-blue-300;
}

.btn-gray-blue {
  width: 32px;
  height: 32px;
  min-width: 32px;
  padding: 0.25rem;
  background-color: $color-gray-blue-300;
  color: $color-text-gray;

  &:after {
    content: none;
    display: none;
  }
}

.dropdown-menu {
  &.profile-action-buttons {
    width: 264px;
    max-width: calc(100vw - 48px);
    padding: 1rem 1rem 0.75rem 1rem;
  }
}
</style>
