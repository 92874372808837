<template>
  <div class="d-flex justify-content-between align-items-center p-1r">
    <h4 class="d-inline-block m-0">
      Omnichannel 3C+
    </h4>
    <app-button
      :loading="loadingFlags.createNewChat"
      :tooltip="$t('new_chat')"
      class="lh-base"
      data-test="newChat-button"
      @click="$emit('openNewChat')"
    >
      <i class="fa-regular fa-plus" />
    </app-button>
  </div>
  <div class="d-flex align-items-center py-0-px-1r gap-1r">
    <app-button
      :color="filterOpen? 'btn-primary' : 'btn-gray-blue'"
      class="btn-filter"
      :tooltip="$t('filters')"
      data-test="filterChats-button"
      @click="filterOpen = !filterOpen"
    >
      <span
        class="fa-regular"
        :class="filterOpen? 'fa-close' : 'fa-filter'"
      />
    </app-button>
    <search-input
      class="col rounded input-search-border"
      :placeholder="$t('search_capitalize')"
      @search="(val) => $emit('search', val)"
    />
  </div>
  <select-contact-tags
    v-if="filterOpen"
    class="pt-05r-px-1r-pb-0"
    @select="(val) => $emit('filterByTags', val)"
  />
</template>

<script>
import AppButton from "@components/app-button.vue";
import SearchInput from "@components/search-input.vue";
import {mapGetters} from "vuex";
import SelectContactTags from "@whatsapp/components/tags/select-contact-tags.vue";

export default {
  name: "TalksHeaderAgent",

  emits: ["search", "openNewChat", "filterByTags"],

  components: {
    SelectContactTags,
    SearchInput,
    AppButton
  },

  data() {
    return {
      filterOpen: false,
    }
  },

  computed: {
    ...mapGetters("whatsapp/chat", {
      loadingFlags: "getLoadingFlags",
    }),
  },
}
</script>

<style scoped lang="scss">
.btn-filter {
  height: 40px;
  width: 40px;
}

.input-search-border {
  border: 1px solid #E3EBF6;
  padding: 0 0.5rem;
}

.p-1r {
  padding: 1rem;
}
.py-0-px-1r {
  padding: 0 1rem;
}
.gap-1r {
  gap: 1rem;
}
.pt-05r-px-1r-pb-0 {
  padding: 0.5rem 1rem 0 1rem;
}
</style>