<template>
  <div
    :class="{
      'is-clicked': (openChat && openChat.id == contactItem.id),
      'is-late': isLate
    }"
    class="contact-wrapper d-flex align-items-center cursor-pointer"
    @click="open(contactItem)"
  >
    <div class="col-auto d-flex align-items-center position-relative contact-avatar">
      <emoji-chat-mood
        v-if="!!contactItem.mood"
        :mood="contactItem.mood"
        class="emoji-chat"
      />
      <span class="avatar">
        <img
          :src="chatImage"
          alt="contact"
          class="avatar-img rounded-circle"
          @error="handleImageError"
        >
        <span
          :title="currentChat?.agent?.name || currentChat?.instance?.data?.name"
          v-if="showInitials && getInitials()"
          :class="{'animate': showInitials}"
          class="position-absolute start-0  rounded-circle initials-badge tx-text-200 d-flex justify-content-center align-items-center"
        >
          {{ getInitials() }}
        </span>
      </span>
    </div>
    <div class="col overflow-hidden">
      <div class="d-flex align-items-center justify-content-between">
        <small class="text-truncate">
          <div class="ball" />
          {{ contactItem.instance.data ? contactItem.instance.data.name : contactItem.instance.name }}
        </small>
        <small class="col-auto text-right text-small last-update">
          {{ chatLastMessageTime }}
        </small>
      </div>
      <div class="d-flex align-items-center">
        <h5 class="col mb-0 d-block text-truncate fw-medium contact-name fs-6">
          {{ chatName }}
        </h5>
      </div>
      <div class="d-flex align-items-center contact-previews">
        <p
          class="col h6 d-block fw-light text-truncate mb-0 last-message lh-base"
          :class="{'text-muted': contactItem.last_message_data?.type == 'transfer'}"
        >
          {{ lastMessage ? lastMessage : "" }}
        </p>
        <div
          v-if="isLate"
          :title="queueResponseIsLateData.response_is_late? 'Tempo de fila excedido' : 'Tempo de resposta excedido'"
          class="col-auto d-flex align-items-center justify-content-center badge-notification bg-red-300 tx-offline py-0-px-05r"
        >
          <span
            class="fal fw-medium text-small me-0125r"
            :class="{
              'fa-stopwatch': queueResponseIsLateData.response_is_late,
              'fa-message-slash': lagToResponseData.response_is_late && !queueResponseIsLateData.response_is_late
            }"
          />
          <p class="my-0">
            {{ formatLateTime(lateTime) }}
          </p>
        </div>
        <span
          class="col-auto d-flex align-items-center justify-content-center badge-notification bg-ivr"
          :title="$t('ivr_uppercase')"
          v-if="contactItem.by_active_ivr"
        >
          <span class="far fa-phone-incoming tx-offline" />
        </span>
        <span
          v-if="contactItem.chatbot_active"
          class="fal fa-robot fw-medium tx-blue-300 me-0"
          title="Chatbot"
        />
        <span
          class="col-auto d-flex align-items-center justify-content-center badge-notification bg-primary"
          v-if="contactItem.unread > 0"
        >
          <p class="my-0 tx-offline">
            {{ contactItem.unread > 99 ? "99+" : contactItem.unread }}
          </p>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import {Utils, formatTimestamp, isValidImageUrl, handleImageError} from "@/utils";
import {mapGetters, mapMutations} from "vuex";
import moment from "moment";
import EmojiChatMood from "@whatsapp/components/emoji-chat-mood.vue";

export default {
  components: {EmojiChatMood},
  props: {
    contactItem: {
      default: null,
      type: Object
    },
    showInitials: {
      type: Boolean,
      default: false,
    }
  },

  data() {
    return {
      lateTime: 0,
      lateInterval: null
    }
  },

  mounted() {
    if (this.isLate) {
      this.setLateTimeInSeconds(this.lagToResponseData.late_since || this.queueResponseIsLateData.late_since)
      this.setLateInterval()
    }
  },

  watch: {
    currentChat() {
      if (this.isLate) {
        this.setLateTimeInSeconds(this.lagToResponseData.late_since || this.queueResponseIsLateData.late_since)
        this.setLateInterval()
      } else {
        this.lateTime = 0
        this.unsetLateInterval()
      }
    }
  },

  computed: {
    ...mapGetters("whatsapp/chat", {
      openChat: "getOpenChat",
      chats: "getCurrentChat",
    }),

    chatImage() {
      if (this.contactItem.contact && this.contactItem.contact.image && this.isValidImageUrl(this.contactItem.contact.image))
        return this.contactItem.contact.image;
      else
        return require("@/assets/img/avatar.svg")
    },

    chatName() {
      if (this.contactItem.contact.name_alias) {
        return this.contactItem.contact.name_alias
      }

      if (this.contactItem.contact.name) {
        return this.contactItem.contact.name
      }

      return this.mask(this.contactItem.number, "+## ## ####-#####")
    },

    chatLastMessageTime() {
      const lastMessageUnix = this.contactItem.last_message_data.date || this.contactItem.time
      const lastMessageDate = new Date(lastMessageUnix * 1000);

      let today = new Date();
      today.setHours(0, 0, 0, 0);

      let yesterday = new Date(today);
      yesterday.setDate(today.getDate() - 1);

      if (lastMessageDate >= today) {
        return formatTimestamp(lastMessageUnix, 'HH:mm');
      }

      if (lastMessageDate >= yesterday) {
        return this.$t('yesterday_capitalize');
      }

      if (lastMessageDate.getFullYear() < today.getFullYear()) {
        return formatTimestamp(lastMessageUnix, 'DD/MM/YYYY');
      }

      return formatTimestamp(lastMessageUnix, 'DD/MM');
    },

    currentChat() {
      return this.chats[this.contactItem.id]
    },

    lagToResponseData() {
      return this.currentChat?.lag_to_response
    },

    queueResponseIsLateData() {
      return this.currentChat?.queue_response_is_late
    },

    isLate() {
      return this.lagToResponseData?.response_is_late || this.queueResponseIsLateData?.response_is_late
    },

    lastMessage() {
      if (this.contactItem.last_message_data.length <= 0)
        return ""
      let prefix = "";
      this.contactItem.last_message_data?.send_by_me ? (prefix = this.$t('you') + ": ") : (prefix = "");

      switch (this.contactItem.last_message_data?.type) {
        case "image":
          return prefix + this.$t('image_capitalize');
        case "ptt":
          return prefix + this.$t('audio_capitalize');
        case "audio":
          return prefix + this.$t('audio_capitalize');
        case "voice":
          return prefix + this.$t('audio_capitalize');
        case "video":
          return prefix + this.$t('video');
        case "document":
          return prefix + this.$t('document');
        case "sticker":
          return prefix + this.$t('sticker');
        case "vcard":
          return prefix + this.$t('contact');
        case "transfer":
          return this.$t('received_by_transfer')
        case "template":
          return prefix + this.$t('template')
      }
      return prefix + this.contactItem.last_message_data?.body
    },
  },

  methods: {
    ...mapMutations("whatsapp/chat", ["setOpenChat"]),
    isValidImageUrl,
    handleImageError,

    mask: Utils.mask,
    formatTimestamp,

    open(item) {
      this.setOpenChat(item)
      this.$router.push({path: "/whatsapp/" + item.id});
    },

    setLateTimeInSeconds(lateSince) {
      const lateSinceHour = moment.unix(lateSince);
      const lateSinceInMs = new Date(lateSinceHour).getTime();
      const nowInMs = new Date().getTime();
      const diff = (nowInMs - lateSinceInMs) / 1000;
      this.lateTime = Math.round(diff)
    },

    getHours(timeInSeconds) {
      return Math.round(timeInSeconds / 3600)
    },

    setLateInterval() {
      this.lateInterval = setInterval(() => {
        if (this.getHours(this.lateTime) >= 24) {
          this.unsetLateInterval()
        }

        this.lateTime += 60
      }, 60000)
    },

    unsetLateInterval() {
      clearInterval(this.lateInterval)
    },

    formatLateTime(lateTimeInSeconds) {
      const oneHourInSeconds = 3600;
      const oneDayInSeconds = 24 * oneHourInSeconds;

      if (lateTimeInSeconds > oneDayInSeconds) {
        return "+24h"
      }

      if (lateTimeInSeconds >= oneHourInSeconds) {
        return Math.floor(lateTimeInSeconds / oneHourInSeconds) + "h"
      }

      return Math.floor(lateTimeInSeconds / 60) + "min"

    },


    getInitials() {
      const ownerName = (this.currentChat?.agent?.name || this.currentChat?.agent_name) || this.currentChat?.instance?.data?.name;
      if (!ownerName) {
        return false;
      }
      const initials = ownerName.split(" ");
      const finals = initials.length > 1 ?  initials[initials.length - 1].charAt(0) : initials[initials.length - 1].charAt(1);

      return ownerName.charAt(0) + finals.toUpperCase();
    }
  },
};
</script>

<style lang="scss" scoped>
.is-clicked {
  background-color: #edf2f9;
}

.is-late {
  background-color: #f3dde3 !important;

  &:hover {
    background: #f2c6d1 !important;
  }
}

.contact-wrapper {
  height: 70px;
  padding: 0.5rem;
  border-radius: 0.625rem;

  &:hover {
    background: $color-gray-200;
  }
}

.contact-avatar {
  height: 56px;
  margin-right: 0.5rem
}

.contact-previews {
  height: 25px;
  gap: 0.5rem
}

.avatar {
  width: 2.5rem;
  height: 2.5rem;
}

.ball {
  background-color: rgb(94, 184, 99);
  width: 8px;
  display: inline-block;
  height: 8px;
  border-radius: 50%;
}

.response-is-late {
  color: red !important;
}

.contact-name {
  color: $color-text-solid-black;
}

.last-update, .last-message {
  color: $color-text-200;
}

.badge-notification {
  height: 24px;
  min-width: 24px;
  border-radius: 24px;
  font-size: 0.75rem;
  line-height: 1rem;
}

.initials-badge {
  line-height: 1;
  background-color: #E1E9F4;
  width: 0;
  min-width: 0;
  height: 0;
  min-height: 0;
  aspect-ratio: 1/1;
  font-size: 0.625rem;
  bottom: -10px;
  opacity: 0;

  &.animate {
    width: 20px;
    min-width: 20px;
    height: 20px;
    min-height: 20px;
    animation-name: bounce;
    animation-duration: 0.35s;
    animation-fill-mode: forwards;
    animation-timing-function: ease;
    animation-delay: 0s;
    animation-iteration-count: 1;
    animation-direction: normal;
  }
}

@keyframes bounce {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.25);
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.emoji-chat {
  position: absolute;
  top: 2px;
  left: 0;
  z-index: 1;
}

.py-0-px-05r {
  padding: 0 0.5rem
}
.me-0125r {
  margin-right: 0.125rem;
}
.bg-ivr {
  background-color: #459BAD
}
</style>
