<template>
  <div class="d-flex justify-content-between t-tabs">
    <div
      class="col-auto text-center fw-light t-tab"
      :class="{ active: talksTab === 'my' }"
      data-test="inProgressTab-button"
      @click="updateTalksTab('my')"
    >
      <small class="text-truncate"> {{ $t('chats') }} </small>
      <span
        v-if="unreadConversations"
        class="notification fw-light me-1 ms-2 position-relative"
      >
        <p class="my-0 mw-12p">
          {{ unreadConversations }}
        </p>
        <span
          v-if="hasNotifiedInProgress"
          class="position-absolute top-0 end-0 rounded-circle bg-red-300 notification-sm"
        />
      </span>
    </div>
    <div
      class="col-auto text-center fw-light t-tab"
      :class="{ active: talksTab === 'groups' }"
      data-test="groupsTab-button"
      @click="updateTalksTab('groups')"
    >
      <small class="text-truncate"> {{ $t('groups') }} </small>
      <span
        v-if="unreadGroupsConversations"
        class="notification fw-light me-1 ms-2"
      >
        <p class="my-0 mw-12p">
          {{ unreadGroupsConversations }}
        </p>
      </span>
    </div>
    <div
      class="col-auto text-center fw-light t-tab"
      :class="{ active: talksTab === 'queue' }"
      data-test="queueTab-button"
      @click="updateTalksTab('queue')"
    >
      <small class="text-truncate">{{ $t('capitalizes.queue') }}</small>
      <span
        v-if="queuePagination.total>0"
        class="notification fw-light me-1 ms-2 position-relative"
      >
        <p class="my-0 mw-12p">
          {{ queuePagination.total }}
        </p>
        <span
          v-if="hasNotifiedQueue"
          class="position-absolute top-0 end-0 rounded-circle bg-red-300 notification-sm"
        />
      </span>
    </div>

    <div class="dropdown text-center fw-light t-tab col-auto">
      <button
        class="btn btn-sm px-3 shadow-none border-0"
        type="button"
        data-bs-toggle="dropdown"
        aria-expanded="false"
        data-test="moreTabs-button"
      >
        <i class="fe fe-more-horizontal" />
      </button>
      <div class="dropdown-menu">
        <h5 class="text-medium">
          {{ $t('more_options') }}
        </h5>
        <div>
          <div
            v-if="!isAgent"
            class="dropdown-item"
            :class="{ active: talksTab === 'inChatbot' }"
            @click="updateTalksTab('inChatbot')"
          >
            Chatbot
          </div>
          <div
            class="dropdown-item"
            :class="{ active: talksTab === 'snooze' }"
            data-test="snoozeTab-button"
            @click="updateTalksTab('snooze')"
          >
            <p class="m-0">
              {{ $t('list_metrics_on_hold') }}
            </p>
          </div>
          <div
            class="dropdown-item"
            :class="{ active: talksTab === 'finished' }"
            data-test="finishedTab-button"
            @click="updateTalksTab('finished')"
          >
            <p class="m-0">
              {{ $t('compĺeted') }}
            </p>
          </div>
          <hr class="navbar-divider">
          <div
            class="dropdown-item"
            data-test="contactsTab-button"
            @click="$emit('showContactsPanel')"
          >
            <p class="m-0">
              <span class="far fa-user-group fs-6" />
              {{ $t('contacts') }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: "TalksTabs",

  emits: ["showContactsPanel"],

  computed: {
    ...mapGetters("auth", {
      isAgent: "isAgent",
    }),

    ...mapGetters("whatsapp/chat", {
      talksTab: "getTalksTab",
      unreadConversations: "getUnreadConversations",
      unreadGroupsConversations: "getUnreadGroupsConversations",
      hasNotifiedInProgress: "getHasNotifiedInProgress",
      hasNotifiedQueue: "getHasNotifiedQueue",
      queuePagination: "getQueuePagination",
    }),
  },

  methods: {
    ...mapActions("whatsapp/chat", ["updateTalksTab"]),
  }
}
</script>

<style scoped lang="scss">
.t-tabs {
  border-bottom: 1px solid #c5c5c5;
  background-color: $color-text-light !important;
  height: 33px;
  margin-top: 0.5rem;
  padding: 0 1rem;
}

.t-tab {
  border-bottom: none;
  color: $color-text-200;
  position: relative;
  top: 1px;
  cursor: pointer;

  small {
    font-size: 14px !important;
  }

  &.active {
    color: $color-blue-300;
    font-weight: 500;
    border-bottom: 2px solid $color-blue-300 !important;
  }
}

.notification {
  display: inline-block;
  line-height: 18px !important;
  font-size: 12px;
  background-color: #e1e9f4;
  color: #283e5d;
  border-radius: 16px;
  padding: 0.1rem 0.3rem;
}

.dropdown {
  .btn {
    border-radius: $whatsapp-border-radius !important;

    &:active,
    &:hover {
      background-color: $color-blue-300 !important;
      color: #ffffff !important;
    }
  }

  h5 {
    margin-bottom: 1rem;
  }

  .dropdown-menu {
    width: 207px;
    padding: 1rem;
    box-shadow: 0 0.25rem 1.125rem 0 rgba(34, 54, 77, 0.25);
    border: none;
    border-radius: 0.625rem;
  }

  .dropdown-item {
    color: $color-text-solid-black;
    width: auto;
    display: block;
    border-radius: 8px !important;
    padding-left: 0.75rem;
    cursor: pointer;

    &:active,
    &:hover {
      background-color: $color-gray-blue-300 !important;
      color: $color-blue-300 !important;
    }
  }

  .navbar-divider {
    margin: 0.5rem 0;
  }
}

.mw-12p {
  min-width: 12px;
}

.notification-sm {
  height: 4px;
  width: 4px;
}
</style>