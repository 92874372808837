<template>
  <transition
    name="fade"
    mode="in-out"
  >
    <div
      v-if="socketIOStatus === 'disconnected' || !isOnline"
      class="d-flex justify-content-start align-items-center no-connection"
    >
      <div
        class="rounded-circle d-flex align-items-center justify-content-center no-connection-avatar"
        :class="isOnline? 'bg-blue-200 text-white' : 'bg-yellow-300'"
      >
        <span
          v-if="!isOnline"
          class="far fa-wifi"
        />
        <div
          v-else
          class="wifi-icons"
        >
          <span class="far fa-wifi-weak" />
          <span class="far fa-wifi-fair" />
          <span class="far fa-wifi" />
        </div>
      </div>

      <div>
        <h6 class="mb-0">
          {{ isOnline ? $t("connecting") : $t('no_connection') }}
        </h6>
        <p class="mb-0 text-small">
          {{ isOnline ? $t("whatsapp_syncing_message") : $t('no_connection_description') }}
        </p>
      </div>
    </div>
  </transition>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "NoConnection",

  computed: {
    ...mapGetters("system", {
      isOnline: "getIsOnline",
      socketIOStatus: "getSocketIOStatus"
    })
  },
}
</script>

<style lang="scss" scoped>
.no-connection {
  height: 72px;
  padding: 0.5rem 1.25rem;
  gap: 1rem;
  margin: 0.5rem 0.5rem 0 0.5rem
}

.no-connection-avatar {
  height: 56px;
  width: 56px;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}

.wifi-icons {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.wifi-icons span {
  position: absolute;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.wifi-icons span:nth-child(1) {
  animation: wifiAnim 3s infinite;
}

.wifi-icons span:nth-child(2) {
  animation: wifiAnim 3s infinite 1s;
}

.wifi-icons span:nth-child(3) {
  animation: wifiAnim 3s infinite 1.5s;
}

@keyframes wifiAnim {
  0%, 100% {
    opacity: 0;
  }
  33% {
    opacity: 1;
  }
}
</style>
