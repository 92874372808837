<template>
  <div>
    <div class="d-flex flex-wrap manager-top-bar">
      <filter-dropdown
        :title="$t('agents_omni')"
        enable-search
        :initial-selected-id="filters.agent_id || ''"
        :list="agentsList"
        :loading="loadingFlags.fetchAgents"
        data-test="agentsFilter-button"
        @radio-selected="filterByAgent($event)"
      />
      <filter-dropdown
        :title="$t('team_omni')"
        enable-search
        :list="teamsList"
        :initial-selected-id="filters.team_id || ''"
        :loading="loadingFlags.fetchTeams"
        data-test="teamsFilter-button"
        @radio-selected="filterByTeam($event)"
      />
      <filter-dropdown
        :title="$t('channel_group')"
        enable-search
        :list="categorizedList"
        :categorize="true"
        :initial-selected-id="filters.group_channel_id || ''"
        :loading="loadingFlags.fetchGroupChannels"
        data-test="connectionsFilter-button"
        @radio-selected="filterByGroupChannel($event)"
      />
      <select-contact-tags @select="filterByTags" />
    </div>
  </div>
</template>

<script>
import FilterDropdown from "@components/filter-dropdown.vue";
import {mapActions, mapGetters} from "vuex";
import SelectContactTags from "@whatsapp/components/tags/select-contact-tags.vue";

export default {
  emits: ["filterAgent"],

  components: {SelectContactTags, FilterDropdown },

  data() {
    return {
      filters: {
        agent_id: null,
        team_id: null,
        group_channel_id: null,
      },
    };
  },

  computed: {
    ...mapGetters("whatsapp/chat", {
      chatFilters: "getFilters",
    }),

    ...mapGetters("manager/whatsapp_filters", {
      agentsList: "getAgents",
      teamsList: "getTeams",
      groupChannelsList: "getGroupChannels",
      loadingFlags: "getLoadingFlags"
    }),

    categorizedList () {
      const active = this.$t("actives")
      const deleted = this.$t("excluded")
      let list = {
        [active]: [],
        [deleted]: []
      }
      this.groupChannelsList.forEach(gc => {
        gc.status === "deleted" ? list[deleted].push(gc) : list[active].push(gc);
      })
      return list
    }
  },

  created() {
    const filterKeys = ["agent_id", "team_id", "group_channel_id", "tags_ids"];
    filterKeys.forEach(key => this.filters[key] = this.chatFilters[key]);

    this.fetchAgents()
    this.fetchTeams()
    this.fetchGroupChannels()
  },

  methods: {
    ...mapActions("manager/whatsapp_filters", [
      "fetchAgents",
      "fetchTeams",
      "fetchGroupChannels"
    ]),
    ...mapActions("whatsapp/chat", [
      "clearChats"
    ]),

    filterByAgent(id) {
      this.clearChats();
      this.filters.agent_id = id
      this.$emit("filterAgent", this.filters)
    },

    filterByTeam(id) {
      this.clearChats();
      this.filters.team_id = id
      this.$emit("filterAgent", this.filters)
    },

    filterByGroupChannel(id) {
      this.clearChats();
      this.filters.group_channel_id = id
      this.$emit("filterAgent", this.filters)
    },

    filterByTags(ids) {
      this.clearChats();
      this.filters.tags_ids = ids
      this.$emit("filterAgent", this.filters)
    }
  },
};
</script>

<style scoped lang="scss">
.manager-top-bar {
  padding: 0.5rem 1rem 0 1rem;
  gap: 0.5rem;
}
</style>
