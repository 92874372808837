<template>
  <div class="background-chat d-flex align-items-center justify-content-center">
    <div class="text-center">
      <img
        src="@/assets/img/bad-plus.svg"
        class="mb-1r"
      >
      <h2 class="mb-1r">
        {{ $t("omni_no_access") }}
      </h2>
      <p class="mb-0 fs-4">
        {{ $t("contact_manager") }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "WhatsappNoAccess"
}
</script>

<style scoped lang="scss">
.background-chat {
  background-color: #eaf1fb;
  background-image: url("~@/assets/img/whatsapp-background.svg") !important;
  background-size: 10%;
  background-repeat: repeat;
  background-blend-mode: color-burn;
  padding-left: 66px !important;
}

.mb-1r{
  margin-bottom: 1rem;
}
</style>