<template>
  <transition
    name="fade"
    mode="in-out"
  >
    <div
      v-if="loadingFlags.reconnectWhatsapp"
      class="d-flex justify-content-start align-items-center syncing"
    >
      <div class="rounded-circle bg-blue-200 text-white d-flex align-items-center justify-content-center syncing-avatar">
        <span class="far fa-arrows-rotate spinner" />
      </div>
      <div>
        <h6 class="mb-0">
          {{ $t("whatsapp_syncing") }}
        </h6>
        <p class="mb-0 text-small">
          {{ $t("whatsapp_syncing_message") }}
        </p>
      </div>
    </div>
  </transition>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "SyncingChats",

  computed: {
    ...mapGetters("whatsapp/chat", {
      loadingFlags: "getLoadingFlags"
    })
  }
}
</script>

<style lang="scss" scoped>
.syncing {
  height: 72px;
  padding: 0.5rem 1.25rem;
  gap: 1rem;
  margin: 0.5rem 0.5rem 0 0.5rem
}

.syncing-avatar {
  height: 56px;
  width: 56px;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}

.spinner {
  display: inline-block;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>