<template>
  <manager-side-bar v-if="isSupervisorOrManager && userRects.width >= 1200 " />
  <div v-if="isSupervisorOrManager || hasAccessToWhatsapp">
    <loading-page-whatsapp
      class="fade-in"
      :class="{ 'fade-out': enableFadeOut }"
      v-show="!allFlagsLoaded"
      :percentage="loadPercentage"
    />
    <div
      class="bg-white mh-100 w-100"
      :class="[
        isAgent && userRects.width > 1199.999? 'vh-agent' : 'vh-100',
        allFlagsLoaded? 'd-flex flex-column' : 'd-none',
        {
          'sidebar-margin': userRects.width > 1199.999
        }
      ]"
    >
      <div
        class="d-flex"
        :class="[ offline? 'h-offline' : 'h-100']"
      >
        <div
          v-if="userRects.width > 1199.999 || ( userRects.width <= 1200 && !$route.params.id)"
          class="col-12 col-xl-3 position-relative whatsapp-talks"
          data-test="whatsappChats-div"
        >
          <whatsapp-talks />
        </div>
        <div
          v-if="userRects.width > 1199.999 || ( userRects.width <= 1200 && $route.params.id)"
          class="col-12 col-xl"
        >
          <whatsapp-view />
        </div>
      </div>
      <footer-no-connection />
    </div>
  </div>
  <whatsapp-no-access
    :class="isAgent? 'vh-agent' : 'vh-100'"
    v-else-if="!hasAccessToWhatsapp"
  />
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import ManagerSideBar from "@/scopes/dialer/manager/components/short-sidebar.vue";
import LoadingPageWhatsapp from "@whatsapp/components/loading-page-whatsapp.vue";
import WhatsappTalks from "@whatsapp/components/whatsapp-talks/whatsapp-talks.vue";
import WhatsappView from "@whatsapp/components/whatsapp-view.vue";
import WhatsappNoAccess from "@whatsapp/components/whatsapp-no-access.vue";
import FooterNoConnection from "@whatsapp/components/footer-no-connection.vue";

export default {
  components: {
    FooterNoConnection,
    WhatsappView,
    WhatsappNoAccess,
    WhatsappTalks,
    LoadingPageWhatsapp,
    ManagerSideBar
  },

  data() {
    return {
      timer: null,
      enableFadeOut: false,
      allFlagsLoaded: false,
      test: false
    };
  },

  beforeMount() {
    this.init();
  },

  mounted() {
    setTimeout(() => {
      this.test = true
    }, 1000);
  },

  beforeUnmount() {
    clearInterval(this.timer);
  },

  computed: {
    ...mapGetters("whatsapp/instance", {
      instances: "getInstances",
      groupChannel: "getGroupChannel",
      groupChannels: "getGroupChannels"
    }),

    ...mapGetters("auth", {
      hasAccessToWhatsapp: "hasAccessToWhatsapp",
      isWhatsappAllowed: "isWhatsappAllowed",
      isSupervisorOrManager: "isSupervisorOrManager",
      isAgent: "isAgent"
    }),

    ...mapGetters("manager/chats_report", {
      spiedChat: "getSpiedChat"
    }),

    ...mapGetters("whatsapp/chat", {
      firstMessagesAreLoaded: "getFirstMessagesAreLoaded",
      loadPercentage: "getLoadPercentage"
    }),

    ...mapGetters("system", {
      isOnline: "getIsOnline",
      socketIOStatus: "getSocketIOStatus",
      userRects: "getUserRects"
    }),

    offline() {
      return !this.isOnline || this.socketIOStatus === 'disconnected'
    }
  },

  methods: {
    ...mapActions("whatsapp/instance", [
      "findInstances",
      "ping",
      "fetchGroupChannels",
      "fetchQualificationsById"
    ]),

    init() {
      if (this.hasAccessToWhatsapp || this.isWhatsappAllowed) {
        this.fetchGroupChannels().then(() => {
          if (this.groupChannels.length > 0) {
            let foundGroupChannel = this.groupChannels.find((groupChannel) => {
              return groupChannel.channels.data.find(channel => {
                return channel.status
              })
            })
            if (foundGroupChannel) {
              this.fetchQualificationsById(foundGroupChannel.id)
            }
          }
        })
        if (!this.instances.length > 0) {
          this.findInstances()
        }
        this.ping();
        this.timer = setInterval(() => {
          this.ping();
        }, 50000);
      }
    },
  },

  watch: {
    firstMessagesAreLoaded: {
      immediate: true,
      deep: true,
      handler(el) {
        if (Object.values(el).every(flag => flag === true)) {
          this.enableFadeOut = true;
          setTimeout(() => {
            this.allFlagsLoaded = true;
            if (this.spiedChat) {
              this.$router.push(`/whatsapp/${this.spiedChat}`);
            }
          }, 1000);
        }
      }
    },
  },
};
</script>

<style scoped lang="scss">
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.fade-in {
  animation: fadeIn 1s;
}

.fade-out {
  animation: fadeOut 1s;
}

.sidebar-margin {
  padding-left: 66px !important;
}

.vh-agent {
  height: calc(100vh - 56px);
}

.h-offline {
  height: calc(100% - 40px);
}
</style>

<style lang="scss">
.whatsapp-talks {
  min-width: 392px;
  border-right: 1px solid #dddfe3;

  .offcanvas-backdrop {
    position: absolute !important;
    width: 100% !important;
  }
}

@media only screen and (max-width: 1199.999px) {
  .whatsapp-talks {
    min-width: 100%;
  }
}
</style>
