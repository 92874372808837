<template>
  <div
    v-if="!isOnline || socketIOStatus === 'disconnected'"
    class="d-flex align-items-center justify-content-between w-100 footer-no-connection gap-1r"
  >
    <div class="d-flex align-items-center gap-1r">
      <span class="far fa-wifi-slash" />
      {{ $t('we_detected_an_issue_with_the_internet_network_we_are_restoring_the_connection_please_wait') }}
    </div>
    <div
      class="spinner-border spinner-border-md"
      role="status"
    >
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "FooterNoConnection",

  computed: {
    ...mapGetters("system", {
      isOnline: "getIsOnline",
      socketIOStatus: "getSocketIOStatus"
    })
  }
}
</script>

<style scoped lang="scss">
.footer-no-connection {
  border: 1px solid #FFE9A9;
  background-color: #FFF6BF;
  height: 40px;
  color: #5D4900;
  padding: 0.5rem 1rem;
}

.gap-1r {
  gap: 1rem;
}

.spinner-border-md {
  border-width: 3px;
  height: 20px;
  width: 20px;
  margin-top: -1px;
}
</style>