<template>
  <div class="d-flex flex-wrap align-items-center justify-content-between whatsapp-filters">
    <div class="col-auto">
      <input
        type="checkbox"
        class="btn-check"
        name="sort"
        id="sort"
        autocomplete="off"
        v-model="sort"
        @change="toggleSortSearch('sort')"
      >
      <label
        class="w-100 btn border-0 lh-1 d-inline-flex justify-content-center align-items-center check-filter"
        :class="{
          'btn-primary': sort,
          'btn-gray-blue': !sort
        }"
        for="sort"
        title="Filtros"
      >
        <i
          class="fa-regular"
          :class="{
            'fa-close': sort,
            'fa-filter': !sort
          }"
        />
      </label>
    </div>
    <div
      class="col"
      v-for="(filter, fIndex) in filters"
      :key="`filter-${fIndex}`"
    >
      <input
        type="radio"
        class="btn-check"
        :name="`filter-${filter.value}`"
        :id="`filter-${filter.value}`"
        autocomplete="off"
        :value="filter.value"
        v-model="selectedFilter"
        @change="$emit('filterBy', selectedFilter)"
        :disabled="disableOwnerFilters"
      >
      <label
        class="w-100 btn border-0 lh-1 d-inline-flex justify-content-center align-items-center gap-2 check-filter check-filter-sm"
        :class="{
          'btn-primary': selectedFilter === filter.value,
          'btn-gray-blue': selectedFilter !== filter.value
        }"
        :for="`filter-${filter.value}`"
      >
        <i :class="filter.icon" />
        {{ filter.label }}
      </label>
    </div>
    <div class="col-auto">
      <input
        type="checkbox"
        class="btn-check"
        name="search"
        id="search"
        autocomplete="off"
        v-model="search"
        @change="toggleSortSearch('search')"
      >
      <label
        class="w-100 btn border-0 lh-1 d-inline-flex justify-content-center align-items-center check-filter"
        :class="{
          'btn-primary': search,
          'btn-gray-blue': !search
        }"
        for="search"
        title="Buscar"
      >
        <i class="fa-regular fa-magnifying-glass" />
      </label>
    </div>
  </div>
</template>

<script>

import {mapGetters} from "vuex";

export default {
  name: "WhatsappFilters",
  emits: [
    "sortSearch",
    "filterBy"
  ],

  data() {
    return {
      filters: [
        {
          icon: "fa-regular fa-users",
          label: this.$t('capitalizes.all'),
          value: "all"
        },
        {
          icon: "fa-regular fa-user",
          label: this.$t('capitalizes.only_me'),
          value: "me"
        },
      ],

      selectedFilter: 'all',
      sortSearch: false,
      sort: false,
      search: false,
    }
  },

  computed: {
    ...mapGetters("whatsapp/chat", {
      talksTab: "getTalksTab"
    }),

    disableOwnerFilters() {
      return ["groups", "queue", "inChatbot"].includes(this.talksTab)
    }
  },

  methods: {
    toggleSortSearch(value) {
      if (value === 'sort') {
        this.search = false;
      }

      if (value === 'search') {
        this.sort = false;
      }

      this.$emit("sortSearch", value);
    }
  }
}
</script>

<style scoped lang="scss">
.whatsapp-filters {
  padding: 0 1rem;
  gap: 0.5rem;
}

.btn-primary,
.btn-gray-blue {
  box-shadow: none !important;
}

.btn-check:checked + .btn-primary {
  background-color: #3057f2;
}

.btn-gray-blue {
  background-color: #E1E9F4;
  color: #677C92;

  &:hover {
    background-color: #D2DDEC;
  }
}

.check-filter {
  min-width: 32px;
  min-height: 32px;
  padding: 0.5rem;

  &-sm {
    padding: 0.5rem 1rem;
    font-size: 0.75rem;
  }
}
</style>