<template>
  <div class="h-100 background-chat position-relative">
    <router-view
      name="chat"
      :key="$route.fullPath"
    />
  </div>
</template>

<script>
export default {
  name: "WhatsappView"
}
</script>

<style scoped lang="scss">
.background-chat {
  background-color: #eaf1fb;
  background-image: url("~@/assets/img/whatsapp-background.svg") !important;
  background-size: 10%;
  background-repeat: repeat;
  background-blend-mode: color-burn;
}
</style>