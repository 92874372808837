<template>
  <div
    class="list-group list-group-flush talks"
    :class="{
      'col': Object.values(groups).length > 0,
    }"
    id="groups-tab"
    ref="groups-tab"
  >
    <contact-talk
      v-for="item in groups"
      :key="item.id"
      :contact-item="item"
    />
    <infinity-pagination-observer
      v-if="validationObserver"
      :loading="loadingFlags.fetchGroups"
      :spinner="false"
      parent-container="groups-tab"
      :threshold="0.5"
      margin="10px"
      @reload="nextPage"
    />
    <contact-skeleton-loading v-if="loadingFlags.fetchGroups" />
  </div>
  <div
    v-if="Object.values(groups).length === 0 && !loadingFlags.fetchGroups"
    class="empty d-flex flex-column align-items-center"
  >
    <template v-if="filterFlags.isFilter">
      <div
        v-if="filterFlags.type === 'search'"
        class="d-flex flex-column align-items-center"
        style="width: 180px"
      >
        <img
          src="@assets/img/talks-empty-state/search.svg"
        >
        <p class="text-center tx-text-gray">
          {{ $t('empty_search') }}
        </p>
      </div>
      <div
        v-else-if="filterFlags.type === 'select'"
        class="d-flex flex-column align-items-center"
        style="width: 185px"
      >
        <img
          src="@assets/img/talks-empty-state/filter.svg"
        >
        <p class="text-center tx-text-gray mb-2 fw-bolder fs-5">
          {{ $t('empty_filter') }}
        </p>
        <p class="text-center tx-text-gray fs-6">
          {{ $t('empty_filter_2') }}
        </p>
      </div>
    </template>
    <div
      v-else
      class="d-flex flex-column align-items-center"
      style="width: 160px"
    >
      <img
        src="@assets/img/talks-empty-state/groups.svg"
      >
      <p class="text-center tx-text-gray">
        {{ $t('empty_search') }}
      </p>
    </div>
  </div>
</template>

<script>
import ContactTalk from "@whatsapp/components/contacts-types/contact-talk.vue";
import ContactSkeletonLoading from "@whatsapp/components/contacts-types/contact-skeleton-loading.vue";
import { mapActions, mapGetters } from "vuex";
import InfinityPaginationObserver from "@components/infinity-pagination-observer.vue";

export default {
  components: { InfinityPaginationObserver, ContactSkeletonLoading, ContactTalk },

  props: {
    modelValue: {
      type: Number,
      default: () => 0
    }
  },

  emits: ['update:modelValue'],

  mounted() {
    this.$nextTick(() => {
      document.getElementById("groups-tab").scrollTo(0, this.modelValue);
    });
  },

  computed: {
    ...mapGetters("whatsapp/chat", {
      groups: "getGroups",
      pagination: "getGroupsPagination",
      loadingFlags: "getLoadingFlags",
      filters: "getFilters",
      filterFlags: "getFilterFlags"
    }),

    validationObserver() {
      return this.pagination.current_page <= this.pagination.total_pages && this.pagination.total_pages !== 1;
    },
  },

  methods: {
    ...mapActions("whatsapp/chat", ["fetchGroups", "incrementGroupsCurrentPage"]),
    async fetchGroupsChat() {
      this.incrementGroupsCurrentPage();
      await this.fetchGroups();
    },
    nextPage() {
      if (this.pagination.current_page < this.pagination.total_pages) {
        this.fetchGroupsChat();
      } else if (this.$refs["groups-tab"].scrollHeight < this.$refs["groups-tab"].clientHeight) {
        this.fetchGroupsChat();
      }
    }
  },

  beforeUnmount() {
    this.$emit("update:modelValue", this.$refs["groups-tab"].scrollTop);
  }
};
</script>

<style scoped lang="scss">
.talks {
  //max-height: 85vh;
  overflow-y: auto;
  padding: 0.5rem;
  gap: 0.5rem;
}

::-webkit-scrollbar {
  width: 4px !important;
}

::-webkit-scrollbar-track {
  background: transparent !important;
}

::-webkit-scrollbar-thumb {
  background: #b0c5c2 !important;
  border-radius: 3px !important;
}

::-webkit-scrollbar-thumb:hover {
  background: #6e84a3 !important;
}

.empty {
  padding-top: 80px;

  img{
    margin-bottom: 20px;
  }
}
</style>
