<template>
  <div
    class="box"
    :class="{ 'box-open': boxOpenned }"
  >
    <div
      class="contact d-flex align-items-center"
      @click="open"
      @mouseover="hovered = true"
      @mouseleave="hovered = false"
    >
      <div class="avatar">
        <img
          v-if="contact && contact.image"
          :src="contact.image"
          class="avatar-img rounded-circle"
        >
        <img
          v-else
          src="@assets/img/avatars/profiles/nome-user.png"
          class="avatar-img rounded-circle"
        >
      </div>

      <div class="col ps-3">
        <div class="name mb-1 fs-6 fw-medium">
          <template v-if="contact.name">
            {{ contact.name_alias ? contact.name_alias : contact.name }}
          </template>
          <template v-else>
            {{ contact.number }}
          </template>
        </div>
        <div class="phone small text-medium">
          {{ formatPhoneNumber(contact.number) }}
        </div>
      </div>

      <div
        v-if="contactLoading"
        class="spinner-border spinner-border-sm"
        role="status"
      />
      <span
        v-else-if="boxOpenned"
        v-show="hovered"
        class="fe fe-arrow-up"
      />
      <span
        v-else
        v-show="hovered"
        class="fe fe-arrow-right tx-blue-300 "
      />
    </div>

    <div
      v-if="instances.length > 1"
      ref="collapse"
      class="collapse"
      id="collapse"
    >
      <instances-list
        :contact="contact"
      />
    </div>
  </div>
</template>

<script>
import InstancesList from "../instances-list.vue";

import {Collapse} from "bootstrap";
import {mapActions, mapGetters, mapMutations} from "vuex";
import {formatPhoneNumber} from "@/utils";

export default {
  components: {
    InstancesList
  },

  props: {
    contact: {
      default: null,
      type: Object
    }
  },

  data() {
    return {
      collapsed: null,
      boxOpenned: false,
      hovered: false,
      contactLoading: false
    };
  },

  computed: {
    ...mapGetters("auth", {
      isManager: "isManager"
    }),

    ...mapGetters("whatsapp/contact", {
      loadingFlags: "getLoadingFlags",
      selectedContact: "getSelectedContact",
      contactInstances: "getContactInstances"
    }),

    ...mapGetters("whatsapp/instance", {
      groupChannels: "getGroupChannels",
    }),

    instances() {
      let instances = [];
      if (Object.keys(this.groupChannels).length) {
        for (const groupChannel of this.groupChannels) {
          for (const instance of groupChannel.channels.data) {
            if (instance.status === "connected" ) {
              instances.push(instance)
            }
          }
        }
      }
      return instances
    },
  },

  mounted() {
    if (this.instances.length > 1) {
      this.collapsed = new Collapse(this.$refs.collapse, { toggle: false });
    }
  },

  watch: {
    selectedContact(contact) {
      if (contact.number != this.contact.number) {
        this.collapsed.hide();
        this.boxOpenned = false;
      }
    }
  },

  methods: {
    ...mapActions("whatsapp/contact", ["findContactInstances"]),
    ...mapActions("whatsapp/chat", ["findChat"]),
    ...mapMutations("whatsapp/contact", ["setSelectedContact"]),
    ...mapMutations("whatsapp/chat", ["setEmptyChatContact"]),

    formatPhoneNumber,

    open() {
      if (this.loadingFlags.findContactInstances) return;

      if (this.instances.length > 1) {
        if (this.selectedContact === this.contact) {
          this.collapsed.toggle();
          this.boxOpenned = !this.boxOpenned;
        }
        else {
          this.contactLoading = true
          this.setSelectedContact(this.contact);
          this.findContactInstances(this.contact.number).then(() => {
            this.collapsed.toggle();
            this.boxOpenned = !this.boxOpenned;
          }).finally(()=>{
            this.contactLoading = false
          })
        }
      }
      else {
        this.contactLoading = true
        if(this.isManager) this.redirectToChat(`${this.contact.number}${localStorage.getItem("instanceId")}`)
        else this.redirectToChat(`${this.contact.number}${this.instances[0].id}`);
      }
    },

    redirectToChat(chatId) {
      this.findChat(chatId)
        .then(chat => {
          this.$router.push("/whatsapp/" + chat.id);
        })
        .catch(status => {
          if (status == 404) {
            let emptyChatContact = this.contact;
            emptyChatContact.instance = this.instances[0];

            this.setEmptyChatContact(emptyChatContact);

            this.$router.push("/whatsapp/empty-chat");
          }
        })
        .finally(()=>{
          this.contactLoading = false
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.box {
  margin: 0 0.25rem 0.25rem 0.25rem;
  border-radius: 1rem;

  &:hover {
    background-color: $color-gray-200;
  }
}

.box-open {
  box-shadow: 0rem 0.25rem 1.125rem 0rem rgba(34, 54, 77, 0.12);
  border: 1px solid var(--light-stroke-stroke-1, #e1e9f4);

  &:hover {
    background-color: #fff;
  }
}

.contact {
  padding: 0.75rem 1rem;
  border-radius: 1rem;
  cursor: pointer;
  height: 72px;

  .avatar {
    width: 2.2rem;
    height: 2.2rem;
    margin-right: 5px;
  }

  .name {
    color: $color-text-solid-black;
  }

  .phone {
    color: $color-text-200;
  }
}
</style>
