<template>
  <div class="d-flex justify-content-between align-items-center p-1r">
    <div class="d-flex align-items-center">
      <router-link
        to="/manager/whatsapp"
        class="btn btn-rounded-circle btn-white me-1r"
      >
        <i class="fa-regular fa-chevron-left" />
      </router-link>
      <h4 class="d-inline-block m-0">
        Omnichannel 3C+
      </h4>
    </div>

    <app-button
      :loading="loadingFlags.createNewChat"
      :tooltip="$t('new_chat')"
      class="lh-base"
      data-test="newChat-button"
      @click="$emit('openNewChat')"
    >
      <i class="fa-regular fa-plus" />
    </app-button>
  </div>
  <whatsapp-filters
    @sort-search="setSortSearch"
    @filter-by="(val) => $emit('filterBy', val)"
  />
  <manager-top-bar
    v-show="showManagerTopBar"
    @filter-agent="(val) => $emit('filterAgent', val)"
  />
  <search-input
    v-if="showSearchInput"
    class="col-auto rounded input-search-border"
    @search="(val) => $emit('search', val)"
    :placeholder="$t('search_capitalize')"
    :focus="true"
  />
</template>

<script>
import AppButton from "@components/app-button.vue";
import {mapGetters} from "vuex";
import WhatsappFilters from "@whatsapp/components/whatsapp-filters.vue";
import ManagerTopBar from "@whatsapp/components/manager-top-bar.vue";
import SearchInput from "@components/search-input.vue";

export default {
  name: "TalksHeaderManager",

  emits: ["openNewChat", "filterBy", "search", "filterAgent"],

  components: {
    SearchInput,
    ManagerTopBar,
    WhatsappFilters,
    AppButton
  },

  data() {
    return {
      showManagerTopBar: false,
      showSearchInput: false,
    }
  },

  computed: {
    ...mapGetters("whatsapp/chat", {
      loadingFlags: "getLoadingFlags",
    }),
  },

  methods: {
    setSortSearch(value) {
      if (value === 'search') {
        this.showSearchInput = !this.showSearchInput;
        this.showManagerTopBar = false;
      }

      if (value === 'sort') {
        this.showManagerTopBar = !this.showManagerTopBar;
        this.showSearchInput = false;
      }
    },
  }
}
</script>

<style scoped lang="scss">
.input-search-border {
  border: 1px solid #E3EBF6;
  padding: 0 0.5rem;
  margin: 0.5rem 1rem;
}

.p-1r {
  padding: 1rem;
}
.me-1r {
  margin-right: 1rem;
}
</style>