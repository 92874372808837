<template>
  <offcanvas-new-chat ref="newChatModal" />
  <panel-contacts
    v-if="showContactsPanel"
    @close="showContactsPanel = false"
  />
  <div
    v-else
    class="d-flex flex-column h-100 mh-100 overflow-hidden"
  >
    <talks-header-manager
      v-if="isSupervisorOrManager"
      @open-new-chat="openNewChat"
      @filter-by="setFilterBy"
      @search="search"
      @filter-agent="filterChats"
    />
    <talks-header-agent
      v-if="isAgent"
      @open-new-chat="openNewChat"
      @filter-by-tags="filterByTags"
      @search="search"
    />
    <talks-tabs @show-contacts-panel="showContactsPanel = true" />
    <syncing-chats />
    <no-connection />
    <in-progress-tab
      v-if="talksTab === 'my'"
      v-model="scrollPositions.inProgressPosition"
      @open-modal="openNewChat"
    />
    <groups-tab
      v-if="talksTab === 'groups'"
      v-model="scrollPositions.groupsPosition"
    />
    <queue-tab
      v-if="talksTab === 'queue'"
      v-model="scrollPositions.queuePosition"
      @open-modal="openNewChat"
    />
    <in-chatbot-tab
      v-if="talksTab === 'inChatbot'"
      v-model="scrollPositions.inChatbotProgress"
    />
    <snooze-tab
      v-if="talksTab === 'snooze'"
      v-model="scrollPositions.inSnoozePosition"
    />
    <finished-tab
      v-if="talksTab === 'finished'"
      v-model="scrollPositions.finishedPosition"
    />
  </div>
</template>

<script>
import _ from "lodash";
import {mapGetters, mapActions} from "vuex";
import {Utils} from "@/utils";

import OffcanvasNewChat from "@whatsapp/components/offcanvas-new-chat.vue";
import PanelContacts from "../panel-contacts.vue";
import TalksHeaderManager from "@whatsapp/components/whatsapp-talks/talks-header-manager.vue";
import TalksHeaderAgent from "@whatsapp/components/whatsapp-talks/talks-header-agent.vue";
import TalksTabs from "@whatsapp/components/whatsapp-talks/talks-tabs/talks-tabs.vue";
import NoConnection from "@whatsapp/components/no-connection.vue";
import InProgressTab from "./talks-tabs/in-progress-tab.vue";
import GroupsTab from "./talks-tabs/groups-tab.vue";
import QueueTab from "./talks-tabs/queue-tab.vue";
import SnoozeTab from "./talks-tabs/snooze-tab.vue";
import FinishedTab from "./talks-tabs/finished-tab.vue";
import InChatbotTab from "@whatsapp/components/whatsapp-talks/talks-tabs/in-chatbot-tab.vue";
import SyncingChats from "@whatsapp/components/syncing-chats.vue";

export default {
  data() {
    return {
      showManagerTopBar: false,
      showSearchInput: false,
      originalTitle: "",
      showContactsPanel: false,
      scrollPositions: {
        inProgressPosition: 0,
        groupsPosition: 0,
        queuePosition: 0,
        inChatbotProgress: 0,
        inSnoozePosition: 0,
        finishedPosition: 0
      },
      filterOpen: false
    };
  },

  created() {
    this.resetChatsPagination();
    this.fetchInProgress()
    this.fetchGroups()
    this.fetchQueue()
  },

  mounted() {
    if (!/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|touch/i.test(navigator.userAgent)) {
      Notification.requestPermission();
    }
    this.originalTitle = document.querySelector("title").text;
  },

  beforeUnmount() {
    document.querySelector("title").text = this.originalTitle;
    if (this.filters.search) {
      this.addFilters({search: ""});
    }
  },

  components: {
    SyncingChats,
    InChatbotTab,
    InProgressTab,
    GroupsTab,
    QueueTab,
    SnoozeTab,
    FinishedTab,
    NoConnection,
    TalksTabs,
    TalksHeaderManager,
    TalksHeaderAgent,
    PanelContacts,
    OffcanvasNewChat,
  },

  computed: {
    ...mapGetters("auth", {
      user: "getUser",
      isAgent: "isAgent",
      isSupervisorOrManager: "isSupervisorOrManager"
    }),

    ...mapGetters("whatsapp/chat", {
      talksTab: "getTalksTab",
      filters: "getFilters"
    }),
  },

  watch: {
    inProgress: {
      deep: true,
      handler(data) {
        data = Object.values(data);
        let notification = data.reduce((n, {unread}) => n + unread, 0);
        if (notification > 0) {
          document.querySelector("title").text = "(" + notification + ") " + this.originalTitle;
        } else {
          document.querySelector("title").text = this.originalTitle;
        }
      }
    }
  },

  methods: {
    mask: Utils.mask,

    ...mapActions("whatsapp/chat", [
      "resetChatsPagination",
      "clearChats",
      "addFilters",
      "fetchInProgress",
      "fetchGroups",
      "fetchQueue",
      "fetchInChatbot",
      "fetchInSnooze",
      "fetchFinished",
    ]),

    open(id) {
      this.$router.push({path: "/whatsapp/" + id});
    },

    openNewChat() {
      this.$refs.newChatModal.open();
    },

    fetchChats() {
      this.resetChatsPagination();
      this.fetchInProgress()
      this.fetchGroups()
      this.fetchQueue()
      switch (this.talksTab) {
        case "inChatbot":
          this.fetchInChatbot()
          break;
        case "snooze":
          this.fetchInSnooze()
          break;
        case "finished":
          this.fetchFinished()
          break;
      }
    },

    filterChats(filters) {
      this.addFilters(filters);
      this.fetchChats();
    },

    search: _.debounce(function (event) {
      this.clearChats();
      this.addFilters({
        search: event
      });
      this.fetchChats();
    }, 500),

    filterByTags(ids) {
      this.clearChats();
      const filters = {
        tags_ids: ids
      }
      this.filterChats(filters)
    },

    setFilterBy(value) {
      this.clearFilters();
      this.clearChats();

      if (value === 'me') {
        this.filterChats({agent_id: this.user.id})
        return
      }

      this.filterChats({agent_id: null});
    },

    clearFilters() {
      this.addFilters({
        search: null,
        group_channel_id: null,
        team_id: null,
        agent_id: null,
      });
    }
  }
};
</script>